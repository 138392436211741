<template>
  <v-footer id="footer" color="grey darken-1" padless>
    <v-row justify="center" no-gutters>
      <v-card flat tile class="text-center" color="grey darken-1">
        <v-card-text>
          <v-btn text color="dark-grey" @click="showAdress = !showAdress"
            >Kontakt</v-btn
          >
          <v-tooltip>
            <template v-slot:activator="{ on, attrs }">
              <v-btn           
                v-bind="attrs"
                v-on="on"
                href="https://www.instagram.com/porzellan_freigedreht/"
                target="_blank"
                text
              >
                <v-icon>mdi-instagram</v-icon>
                @porzellan_freigedreht
              </v-btn>
            </template>
            <span>Instagram</span>
          </v-tooltip>

          <v-btn text color="dark-grey" @click="showGdprBar = !showGdprBar"
            >Datenschutz</v-btn
          >
          <v-btn text color="dark-grey" :to="{ name: 'AdminMarkets' }"
            >Admin</v-btn
          >
          
          <!-- cookies snackbar -->
          <v-snackbar v-model="showCookies">
            Wir nutzen Cookies und u.a. Google Analytics auf unserer Website.
            Einige von ihnen sind essenziell, während andere uns helfen, diese
            Website und Ihre Erfahrung zu verbessern.
            <v-btn @click="showCookies = false">
              Schließen
            </v-btn>
          </v-snackbar>

          <!-- GDP popup -->
          <v-dialog v-model="showGdprBar" width="500">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Datenschutzerklärung</v-card-title
              >
              <v-card-text>
                <h2>Grundlegendes</h2>

                Diese Datenschutzerklärung soll die Nutzer dieser Website über
                die Art, den Umfang und den Zweck der Erhebung und Verwendung
                personenbezogener Daten durch den Websitebetreiber [Ihre
                Kontaktdaten einfügen] informieren. Der Websitebetreiber nimmt
                Ihren Datenschutz sehr ernst und behandelt Ihre
                personenbezogenen Daten vertraulich und entsprechend der
                gesetzlichen Vorschriften. Da durch neue Technologien und die
                ständige Weiterentwicklung dieser Webseite Änderungen an dieser
                Datenschutzerklärung vorgenommen werden können, empfehlen wir
                Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen
                wieder durchzulesen. Definitionen der verwendeten Begriffe (z.B.
                “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art.
                4 DSGVO.

                <h2>Zugriffsdaten</h2>

                Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund
                unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO)
                Daten über Zugriffe auf die Website und speichern diese als
                „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten
                werden so protokolliert:
                <ul>
                  <li>Besuchte Website</li>
                  <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
                  <li>Menge der gesendeten Daten in Byte</li>
                  <li>
                    Quelle/Verweis, von welchem Sie auf die Seite gelangten
                  </li>
                  <li>Verwendeter Browser</li>
                  <li>Verwendetes Betriebssystem</li>
                  <li>Verwendete IP-Adresse</li>
                </ul>

                Die Server-Logfiles werden für maximal 7 Tage gespeichert und
                anschließend gelöscht. Die Speicherung der Daten erfolgt aus
                Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu
                können. Müssen Daten aus Beweisgründen aufgehoben werden, sind
                sie solange von der Löschung ausgenommen bis der Vorfall
                endgültig geklärt ist.

                <h2>Reichweitenmessung und Cookies</h2>

                Diese Website verwendet Cookies zur pseudonymisierten
                Reichweitenmessung, die entweder von unserem Server oder dem
                Server Dritter an den Browser des Nutzers übertragen werden. Bei
                Cookies handelt es sich um kleine Dateien, welche auf Ihrem
                Endgerät gespeichert werden. Ihr Browser greift auf diese
                Dateien zu. Durch den Einsatz von Cookies erhöht sich die
                Benutzerfreundlichkeit und Sicherheit dieser Website. Falls Sie
                nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem
                Endgerät gespeichert werden, können Sie dem Einsatz dieser
                Dateien hier widersprechen:
                <ul>
                  <li>
                    Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
                    <a
                      href="http://optout.networkadvertising.org/?c=1#!/"
                      target="_blank"
                      >http://optout.networkadvertising.org/?c=1#!/</a
                    >
                  </li>
                  <li>
                    Cookie-Deaktivierungsseite der US-amerikanischen Website:
                    <a
                      href="http://optout.aboutads.info/?c=2#!/"
                      target="_blank"
                      >http://optout.aboutads.info/?c=2#!/</a
                    >
                  </li>
                  <li>
                    Cookie-Deaktivierungsseite der europäischen Website:
                    <a
                      href="http://optout.networkadvertising.org/?c=1#!/"
                      target="_blank"
                      >http://optout.networkadvertising.org/?c=1#!/</a
                    >
                  </li>
                </ul>

                Gängige Browser bieten die Einstellungsoption, Cookies nicht
                zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf
                alle Funktionen dieser Website ohne Einschränkungen zugreifen
                können, wenn Sie entsprechende Einstellungen vornehmen.
                Erfassung und Verarbeitung personenbezogener Daten Der
                Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen
                Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt
                ist oder Sie in die Datenerhebung einwilligen. Als
                personenbezogene Daten gelten sämtliche Informationen, welche
                dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen
                zurückverfolgt werden können – also beispielsweise Ihr Name,
                Ihre E-Mail-Adresse und Telefonnummer. Diese Website können Sie
                auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur
                Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne
                Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen
                Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei
                oder der Name Ihres Internet-Providers. Durch die Anonymisierung
                der Daten sind Rückschlüsse auf Ihre Person nicht möglich.

                <h2>Umgang mit Kontaktdaten</h2>

                Nehmen Sie mit uns als Websitebetreiber durch die angebotenen
                Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben
                gespeichert, damit auf diese zur Bearbeitung und Beantwortung
                Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre
                Einwilligung werden diese Daten nicht an Dritte weitergegeben.

                <h2>Google Analytics</h2>

                Diese Website nutzt aufgrund unserer berechtigten Interessen zur
                Optimierung und Analyse unseres Online-Angebots im Sinne des
                Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“,
                welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain
                View, CA 94043, USA) angeboten wird. Der Dienst (Google
                Analytics) verwendet „Cookies“ – Textdateien, welche auf Ihrem
                Endgerät gespeichert werden. Die durch die Cookies gesammelten
                Informationen werden im Regelfall an einen Google-Server in den
                USA gesandt und dort gespeichert. Google LLC hält das
                europäische Datenschutzrecht ein und ist unter dem
                Privacy-Shield-Abkommen zertifiziert:
                <a
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                  target="_blank"
                  >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a
                >

                Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse
                der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des
                Europäischen Wirtschaftsraum und in den anderen Vertragsstaaten
                des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse
                zunächst ungekürzt in die USA an einen Server von Google
                übertragen und dort gekürzt. Durch diese Kürzung entfällt der
                Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte
                IP-Adresse des Nutzers wird nicht mit anderen von Google
                gespeicherten Daten kombiniert. Im Rahmen der Vereinbarung zur
                Auftragsdatenvereinbarung, welche wir als Websitebetreiber mit
                der Google Inc. geschlossen haben, erstellt diese mithilfe der
                gesammelten Informationen eine Auswertung der Websitenutzung und
                der Websiteaktivität und erbringt mit der Internetnutzung
                verbundene Dienstleistungen. Die von Google in unserem Auftrag
                erhobenen Daten werden genutzt, um die Nutzung unseres
                Online-Angebots durch die einzelnen Nutzer auswerten zu können,
                z. B. um Reports über die Aktivität auf der Website zu
                erstellen, um unser Online-Angebot zu verbessern. Sie haben die
                Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu
                verhindern, indem Sie in Ihrem Browser entsprechende
                Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie
                auf alle Funktionen dieser Website ohne Einschränkungen
                zugreifen können, wenn Ihr Browser keine Cookies zulässt.
                Weiterhin können Sie durch ein Browser-Plugin verhindern, dass
                die durch Cookies gesammelten Informationen (inklusive Ihrer
                IP-Adresse) an die Google Inc. gesendet und von der Google Inc.
                genutzt werden. Folgender Link führt Sie zu dem entsprechenden
                Plugin:
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  targe="blank"
                  >https://tools.google.com/dlpage/gaoptout?hl=de</a
                >. Alternativ verhindern Sie mit einem Klick auf diesen Link
                (WICHTIG: Opt-Out-Link einfügen), dass Google Analytics
                innerhalb dieser Website Daten über Sie erfasst. Mit dem Klick
                auf obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr
                Browser muss die Speicherung von Cookies also hierzu
                grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig, ist
                ein erneuter Klick auf den Link bei jedem Besuch dieser Website
                vonnöten. Hier finden Sie weitere Informationen zur Datennutzung
                durch die Google Inc.:

                <a
                  href="https://policies.google.com/privacy/partners?hl=de"
                  target="_blank"
                >
                  https://policies.google.com/privacy/partners?hl=de</a
                >
                (Daten, die von Google-Partnern erhoben werden)

                <a
                  href="https://adssettings.google.de/authenticated"
                  target="_blank"
                  >https://adssettings.google.de/authenticated</a
                >
                (Einstellungen über Werbung, die Ihnen angezeigt wird)

                <a
                  href="https://policies.google.com/technologies/ads?hl=de"
                  target="_blank"
                  >https://policies.google.com/technologies/ads?hl=de</a
                >
                (Verwendung von Cookies in Anzeigen)

                <h2>Rechte des Nutzers</h2>

                Sie haben als Nutzer das Recht, auf Antrag eine kostenlose
                Auskunft darüber zu erhalten, welche personenbezogenen Daten
                über Sie gespeichert wurden. Sie haben außerdem das Recht auf
                Berichtigung falscher Daten und auf die
                Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen
                Daten. Falls zutreffend, können Sie auch Ihr Recht auf
                Datenportabilität geltend machen. Sollten Sie annehmen, dass
                Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine
                Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.

                <h3>Löschung von Daten</h3>

                Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur
                Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung)
                kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von
                uns gespeicherte Daten werden, sollten sie für ihre
                Zweckbestimmung nicht mehr vonnöten sein und es keine
                gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine
                Löschung nicht durchgeführt werden kann, da die Daten für
                zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine
                Einschränkung der Datenverarbeitung. In diesem Fall werden die
                Daten gesperrt und nicht für andere Zwecke verarbeitet.

                <h3>Widerspruchsrecht</h3>

                Nutzer dieser Webseite können von ihrem Widerspruchsrecht
                Gebrauch machen und der Verarbeitung ihrer personenbezogenen
                Daten zu jeder Zeit widersprechen. Wenn Sie eine Berichtigung,
                Sperrung, Löschung oder Auskunft über die zu Ihrer Person
                gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl.
                der Erhebung, Verarbeitung oder Verwendung Ihrer
                personenbezogenen Daten haben oder erteilte Einwilligungen
                widerrufen möchten, wenden Sie sich bitte an folgende
                E-Mail-Adresse: webmaster@grimm-mueller.de
              </v-card-text>
              <v-card-actions>
                <v-btn @click="showGdprBar = false">
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- adress popup -->
          <v-dialog v-model="showAdress" width="500" class="dialog">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Kontakt
              </v-card-title>
              <v-list
                two-line
              >
                
                <v-list-item
                  href="https://www.instagram.com/porzellan_freigedreht/"
                  target="_blank"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar 
                        color="grey"            
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="white">mdi-instagram</v-icon>
                      </v-list-item-avatar>
                    </template>
                    <span>Instagram</span>
                  </v-tooltip>
                  @porzellan_freigedreht
                </v-list-item>

                <v-list-item
                  href="mailto:info@grimm-mueller.de"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar 
                        color="grey"            
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="white">mdi-email</v-icon>
                      </v-list-item-avatar>
                    </template>
                    <span>E-Mail</span>
                  </v-tooltip>
                  info@grimm-mueller.de 
                </v-list-item>
                
                
                <v-list-item
                  href="tel:+46732154146"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar 
                        color="grey"            
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="white">mdi-phone</v-icon>
                      </v-list-item-avatar>
                    </template>
                    <span>Telephon</span>
                  </v-tooltip>
                    07321-54146
                </v-list-item>

                <v-list-item
                  href="https://goo.gl/maps/9QM2JQD8wuPaKJPu9"
                  target="_blank"                
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar 
                        color="grey"            
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="white">mdi-map-marker</v-icon>
                      </v-list-item-avatar>
                    </template>
                    <span>Adresse, öffnet in Google Maps</span>
                  </v-tooltip>

                  Im Flügel 55 <br />
                  89522 Heidenheim <br />
                
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-btn @click="showAdress = false">
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-card-text>

        <v-divider> </v-divider>

        <v-card-text justify="center" no-gutters>
          &copy; images {{ new Date().getFullYear() }} — Elena C. Malz and Jule
          A. Müller
        </v-card-text>

        <v-card-text justify="center" no-gutters>
          &copy; design {{ new Date().getFullYear() }} — 
          <a href="https://jakob.design" target="_blank" style="color:black; text-decoration:none;">Jakob R. Müller </a><br />
          made with Vuetify <br />
        </v-card-text>
      </v-card>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "theFooter",
  data() {
    return {
      showGdprBar: false,
      showCookies: true,
      showAdress: false
    };
  }
};
</script>

<style scoped></style>
