<template>
    <div>
        <v-btn
            v-if="notificationExists"
            fab
            x-small
            absolute
            left
            @click="showNotification = !showNotification"
            color="grey darken-1"
        >
            <v-icon 
                color="white"
                :class="{'shake' : animated}"
            >
                mdi-bell-outline
            </v-icon>
            <span v-if="$vuetify.breakpoint.mobile" style="margin-left:110px;">
                Ankündigung
            </span>
        </v-btn>

        <v-dialog 
            v-if="notificationExists" 
            v-model="showNotification" 
            width="500" 
            class="dialog"
        >
            <v-card>
                <v-system-bar color="grey">
                    Ankündigung
                    <v-btn
                        fab
                        text
                        x-small
                        absolute
                        right
                        @click="closePopup"
                    >
                        x
                    </v-btn>
                </v-system-bar>
                <v-img
                    v-if="titleImg"
                    :src="titleImg.url"
                    :lazy-src="titleImg.thumbUrl"
                    alt="Ankündigung"
                    max-height="300"
                >
                    <v-card-title
                        class="white"
                    >
                        {{content.title}}
                    </v-card-title>
                </v-img>
                <v-card-title v-else>
                    {{content.title}}
                </v-card-title>

                <v-card-text>
                    {{content.content}}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import utils from "@/utils";
import { mapGetters } from 'vuex';

export default {
    name: 'notification',
    data() {
        return {
            showNotification: false,
            content: {
            slug: "loading",
            errors: [] // string
            },
            animated: false,
        }
    },
    computed: {
        ...mapGetters(['getImageBySlug']),
        titleImg() {
            return this.getImageBySlug('notification')
        },
        notificationExists() {
            if (this.content.title) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        async loadTextBlocks() {
            try {
                this.content = await utils.loadTextBlock('notification');
                console.log("what we got: " + this.content.title);
                // this.content.content = this.content.content.replace(/\n\r?/g, '<br />')
                this.errors = [];
                
                // if we actually have any notifications to show, we set show to true:
                if (this.notificationExists) {
                    this.showNotification = true
                }

            } catch (e) {
                this.errors.append(e);
            }
        },
        ringBell() {
            const self = this
            self.animated = true
            setTimeout(() => {
                self.animated = false
            }, 1000)
        },
        closePopup() {
            this.showNotification = false
            this.ringBell()
        }
    },
    beforeMount() {
        this.loadTextBlocks();
    }
}
</script> 

<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>